<app-blank-header></app-blank-header>

<div class="container-fluid h-100">
    <div class="row h-100 row-1 container-row" style="margin-top: 100px;">
      <div class="col-md-6 offset-md-3 card-non-hover">
        <div class="sub-heading-text-1-semi-bold" style="margin: 1rem 0 2rem 0; padding: 0 1rem">
          PDF
          <fa-icon style="font-size: 1rem;" class="fa-xs icon-div float-right" [icon]="faArrowLeft" (click)="backtoLanding()"></fa-icon>
        </div>
        <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              style="display: block;"
              [external-link-target]="'blank'"
              [autoresize]="true"
  ></pdf-viewer>
        
  
      </div>
    </div>
  </div>


