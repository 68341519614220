<section id="chefs" class="chefs-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Expert Hands</span>
      <h2>Meet Our Team</h2>
    </div>

    <div class="chefs-slides owl-carousel owl-theme">
      <div class="single-chefs">
        <div class="chefs-image">
          <img src="assets/img/chef-img1.jpg" alt="chef-img" />

          <ul class="chefs-social">
            <li>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
            </li>
          </ul>
        </div>

        <div class="chefs-content">
          <h3>Harish Neotia</h3>
          <span> Founder & CEO </span>
        </div>
      </div>

      <div class="single-chefs">
        <div class="chefs-image">
          <img src="assets/img/chef-img2.jpg" alt="chef-img" />

          <ul class="chefs-social">
            <li>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
            </li>
          </ul>
        </div>

        <div class="chefs-content">
          <h3>Manisha Neotia</h3>
          <span> Co-Founder & Director </span>
        </div>
      </div>

      <div class="single-chefs">
        <div class="chefs-image">
          <img src="assets/img/chef-img3.jpg" alt="chef-img" />

          <ul class="chefs-social">
            <li>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
            </li>
          </ul>
        </div>

        <div class="chefs-content">
          <h3>Tanya Raj</h3>
          <span> Co-Founder & Director </span>
        </div>
      </div>
    </div>
  </div>

  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</section>
