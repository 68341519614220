<section id="about" class="story-area bg-prime">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12 text-center about mt-4">
        <h6>OUR STORY BEGAN TO BE YOURS</h6>
        <h1 class="fs-mouse">A Modern Era <span>in Street Food</span></h1>
      </div>
    </div>
    <div class="row desc">
      <div class="col-sm-6 col-12">
        <p>
          That hustle of having mouthful panipuri while being ready for another
          shot shouldn’t be stopped. With E-Panipurii Kartz, we are enabling
          this opportunity of having panipuri and running the business of
          panipuri keeping taste, health, and hygiene intact.
        </p>
      </div>
      <div class="col-sm-6 col-12">
        <p>
          Ours is a proven business model, with high returns on investment,
          quality ingredients, advanced technology and enriched customer
          experience. we are seeking partners pan India to become a part of our
          vision of serving panipuri with a smile at the doorstep to every
          panipuri hungry soul!
        </p>
      </div>
    </div>

    <div class="col d-flex align-items-center justify-content-center mb-5">
      <span class="read-more text-danger m-0 px-3 py-2 mt-2" routerLink="/aboutus">READ MORE</span>
    </div>
  </div>
  <div class="story-image">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <video class="col-12" controls controlsList="nodownload" disablePictureInPicture [muted]="true" autoplay>
          <source [src]="getAboutVideoURL()" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>

  <div id="particles-js-circle-bubble-1"></div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</section>

<section class="py-5" id="we_offer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>SILENT POINTS</h6>
        <h1 class="fs-mouse text-white">
          What We Offer &nbsp;<span>To Our Partners</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-4 col-10 text-md-left text-center weOffer pr-md-5 straight">
      <div>
        <h1>Verified Panipuri Suppliers</h1>
        <!-- <h1>Touchless Operation</h1>
        <p>
          We enable our franchise members to operate their carts in an
          contactless manner.
        </p> -->
      </div>

      <div>
        <h1>Only Refined Oil Cooked Ingredients</h1>
        <!-- <h1>Quality Food</h1>
        <p>
          Along with hygiene we ensure our food quality is top notch with a
          enjoyable taste.
        </p> -->
      </div>
      <div>
        <h1>FSSAI Certified Raw Materials</h1>
        <!-- <h1>Hygiene</h1>
        <p>All carts serve hygienic food and in a clean manner..</p> -->
      </div>
      <div>
        <h1>Lab Tested Panipuri Masala</h1>
      </div>
      <div>
        <h1>RO Purifier</h1>
      </div>
      <div>
        <h1>Varieties of Panipuri </h1>
      </div>
      <div>
        <h1>Own Authenticated Recipe of Flavor , Chutney & Spices</h1>
      </div>
    </div>
    <div class="col-md-4 col-10 text-md-right text-center weOffer pl-md-5">
      <div>
        <h1>Standard Operating Procedures</h1>
        <!-- <h1>Food Safety</h1>
        <p>Safety is our top priority when it comes to serving cutomers.</p> -->
      </div>

      <div>
        <h1>Food Quality/Safety & Hygiene Training</h1>
        <!-- <h1>Variety</h1>
        <p>
          We offer our customer tons of choices to choose from and satisfy their
          appetite.
        </p> -->
      </div>
      <div>
        <h1>Online Assistance & Customer Support</h1>
        <!-- <h1>Contactless Serving</h1>
        <p>All carts fully automated and customers are served contactlessly.</p> -->
      </div>
      <div>
        <h1>Branding & Marketing Assistance</h1>
      </div>
      <div>
        <h1>Google Business Listings</h1>
      </div>
      <div>
        <h1>Ready to Start Setup</h1>
      </div>
      <div>
        <h1>Low Investment High Return </h1>
      </div>
    </div>
  </div>
</section>

<section id="menu" class="">
  <div class="container-fluid pt-5">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>WHAT’S ON OUR MENU</h6>
        <h1 class="fs-mouse text-white">
          E-Panipuricart’s &nbsp;<span>Speciality</span>
        </h1>
      </div>
    </div>
  </div>


  <!--
  <div class="row justify-content-center">
    <div class="col-md-1 d-none d-md-block"> -->
  <!-- <i class="fal fa-long-arrow-left h3  mb-0"></i> -->
  <!--   <div
        class="d-flex flex-column justify-content-center align-items-end h-100"
      >
        <img src="../../../../assets/img/Vector 11.svg" alt="" />
        BACK
      </div>
    </div>
    <div class="col-md-10 col-11 text-left spacility my-4 d-flex">
      <div class="center">
        <img src="../../../../assets/img/image1.png" alt="" />
      </div>
      <div class="">
        <img src="../../../../assets/img/image2.png" alt="" />
      </div>
      <div class="center">
        <img src="../../../../assets/img/image3.png" alt="" />
      </div>
    </div>
    <div class="col-md-1 d-none d-md-block">
      <div
        class="
          d-flex
          h-100
          flex-column
          justify-content-center
          align-items-start
        "
      >
        <img src="../../../../assets/img/Vector 10.svg" alt="" />
        NEXT
      </div>
    </div>
  </div> -->

  <div slickContainer #slickController="slick" [slickConfig]="config">
    <ng-container *ngIf="slickController.initialize">
      <div *ngFor="let item of dynamicSlides; let index = index">
        <div slickItem>
        <img  [src]="item.src" [alt]="item.alt"
          [title]="item.title">
          <div class="caption">{{item.title}}</div>
        </div>
      </div>
    </ng-container>
  </div>


  <!-- <div style="width: 920px; margin: auto">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of dynamicSlides">
      <ng-template carouselSlide id="{{slide.id}}">
        <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title">
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div> -->

</section>

<section id="silentF" class="py-3 bg-prime">
  <div class="container">
    <div class="row py-3 align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>PARTNERS</h6>
        <h1 class="fs-mouse">Salient <span>Features</span></h1>
      </div>
    </div>

  <div class="row justify-content-center">

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea">
        <div class="icon"><i class="far fa-magic"></i></div>
        <div>
          <h3>Automatic Dispensing Machine</h3>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea">
        <div class="icon"><i class="far fa-mobile"></i></div>
        <div>
          <h3>Mobile App Support</h3>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea">
        <div class="icon"><i class="far fa-crown"></i></div>
        <div>
          <h3>No Franchisee Fees Or Royalty</h3>

        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea">
        <div class="icon"><i class="fas fa-analytics"></i></div>
        <div>
          <h3>6-8 Months ROI</h3>
        </div>
      </div>
    </div>


   
    
  </div>
</div>
</section>

<section id="recommendation" class="bg-prime py-5">
  <div class="container">
    <div class="row py-3 align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>RECOMMENDATION</h6>
        <h1 class="fs-mouse">We Are <span>Adored By</span></h1>
      </div>
    </div>
    <div class="row" [class.expandContent]="!recommendationExpand">
      <div class="col-8 offset-2 col-md-4 offset-md-4 p-0">
        <div class="p-2">
          <img class="" src="../../../../assets/img/recommendation-restaurant-guru-2023.jpeg"
            alt="recommended by restaurant guru 2023" />
        </div>
      </div>
    </div>
    <app-expand-content (expandCollapse)="expandRecommendation($event)"></app-expand-content>
  </div>
</section>

<section id="patent" class="bg-prime py-5">
  <div class="container">
    <div class="row py-3 align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>PATENT</h6>
        <h1 class="fs-mouse">Our Technology is <span>Patented</span></h1>
      </div>
    </div>
    <div class="row" [class.expandContent]="!patnetExpand">
      <div class="col-12  col-md-12 p-0">
        <div class="p-2 pdf-max">
          <pdf-viewer src="../../../../assets/pdf/Patent Certificate 524077.pdf" [render-text]="true"
            [original-size]="false" style="display: block; " [external-link-target]="'blank'"
            [autoresize]="true"></pdf-viewer>
        </div>
      </div>
    </div>
    <app-expand-content (expandCollapse)="expandPatent($event)"></app-expand-content>
  </div>
</section>

<section id="glance" class="py-3 bg-prime">
  <div class="container">
    <div class="row py-3 align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h1 class="fs-mouse">At a <span>Glance</span></h1>
      </div>
    </div>

  <div class="row justify-content-center">

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea glance">
        <div class="stats fs-mouse">15</div>
        <div>
          <h3>State</h3>
        </div>
        <i class="fad fa-globe"></i>
        
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea glance">
        <div class="stats fs-mouse">40+</div>
        <div>
          <h3>Town</h3>

        </div>
        <i class="fad fa-map-marked-alt"></i>
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class=" silentFea glance">
        <div class="stats fs-mouse">175</div>
        <div>
          <h3>Partners</h3>
        </div>
        <i class="fad fa-handshake"></i>
      </div>
    </div>

    <div class="col-12 col-md-3 col-sm-6 my-2">
      <div class="silentFea glance">
        <div class="stats fs-mouse">3.5 Lacs</div>
        <div>
          <h3>Customers Served</h3>
        </div>
        <i class="fad fa-users-crown"></i>
      </div>
    </div>

   
    
  </div>
</div>
</section>


<section id="successStory" class="bg-prime py-5">
  <div class="container">
    <div class="row py-3 align-items-center">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>Successful Running Company Outlets </h6>
        <h1 class="fs-mouse">Our <span>Success Story</span></h1>
      </div>
    </div>


    <!-- <div class="row">
      <div class="col p-0">
        <div class="p-2">
          <img class="" src="../../../../assets/img/Stall_1.svg" alt="" />
          <img class="mt-3" src="../../../../assets/img/stall_2.png" alt="" />
        </div>
      </div>
      <div class="col p-0">
        <div class="p-2 h-100">
          <img class="h-100" src="../../../../assets/img/main_c.png" alt="" />
        </div>
      </div>
      <div class="col p-0">
        <div class="p-2 d-flex flex-column justify-content-between h-100">
          <img class="" src="../../../../assets/img/last_img.png" alt="" />
          <div>
            <span
              class="read-more text-danger m-0 px-3 py-2 mt-2"
              routerLink="/gallery"
            >
              SEE ALL
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <div class="gallery" [class.expandContent]="!galleryExpand">
      <div class="gallery-wrapper">
        <div class="image" *ngFor="let image of images" (click)="openModal(image)">
          <img src="{{ image.src }}" alt="{{ image.alt }}" class="gallery-img">
        </div>
      </div>

      <!-- <div class="mt-4">
        <span class="read-more text-danger m-0 px-3 py-2 mt-2" routerLink="/gallery">
          SEE ALL
        </span>
      </div> -->

      <div class="modal" *ngIf="showModal" (click)="closeModal()">
        <div class="modal-content">
          <span class="close" (click)="closeModal()">&times;</span>
          <img src="{{ selectedImage?.src }}" class="modal-content" alt="{{ selectedImage?.alt }}">
        </div>
      </div>
    </div>
    <app-expand-content (expandCollapse)="expandGallery($event)"></app-expand-content>
  </div>
</section>

<section id="price" class="bg-prime py-4">
  <div class="container">
    <div class="row py-3 align-items-center mb-3">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>Thank You</h6>
        <h1 class="fs-mouse">
          To our Compliancee <span> and Priceless Aid</span>
        </h1>
      </div>
    </div>

    <!-- <div class="row mb-3">
      <div class="col">
        <div class="
            d-flex
            align-items-center
            justify-content-lg-around justify-content-center
            flex-wrap
          ">
          <img class="" src="../../../../assets/img/price/image 5.png" alt="" />
          <img class="" src="../../../../assets/img/price/image 7.png" alt="" />
          <img class="" src="../../../../assets/img/price/image 8.png" alt="" />
          <img class="" src="../../../../assets/img/price/image 9.png" alt="" />
          <img class="" src="../../../../assets/img/price/image 10.png" alt="" />
        </div>
      </div>
    </div> -->


    <div style="">
      <owl-carousel-o [options]="ComplianceeOptions">
        <ng-container *ngFor="let slide of ComplianceeSlides">
          <ng-template carouselSlide id="{{slide.id}}">
            <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title">
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>


    <div class="row">
      <div class="col d-flex justify-content-center">
        <button class="
            btn btn-primary
            d-flex
            align-items-center
            justify-content-center
            text-nowrap
          " routerLink="/register">
          BE OUR PARTNER <i class="fal fa-long-arrow-right h3 mx-3 mb-0"></i>
        </button>
      </div>
    </div>
  </div>
</section>


<section id="socialfeed" class="bg-prime py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 text-center about">
        <h1 class="fs-mouse">We are <span>Social</span></h1>
        <ul class="social">
          <li><a href="https://www.youtube.com/c/Epanipuricart"><img src="../../../../assets/img/svg/youtube-color-icon.svg">  YouTube</a></li>
          <li><a href="https://www.facebook.com/epanipuricart/"><img src="../../../../assets/img/svg/facebook-round-color-icon.svg">  Facebook</a></li>
          <li><a href="https://www.instagram.com/epanipuricart/"><img src="../../../../assets/img/svg/ig-instagram-icon.svg">  Instagram</a></li>
          <li><a href="https://www.linkedin.com/company/epanipuricart/"><img src="../../../../assets/img/svg/linkedin-app-icon.svg">  Linkedin</a></li>
          <li><a href="https://twitter.com/epanipuricart"><img src="../../../../assets/img/svg/twitter-color-icon.svg">  Twitter</a></li>
         </ul>
      </div>
    </div>
    <div class="row" [class.expandContent]="!socialExpand">
      <div class="col-md-4 col-12 pt-2">
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fepanipuricart%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=487217164786341"
          width="340" height="500"  frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="col-md-4 col-12 ">
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>

      </div>
      <div class="col-md-4 col-12">
        <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25409362' frameborder='0' width='100%' height='500'></iframe>

      </div>
    </div>
    <app-expand-content (expandCollapse)="expandSocial($event)"></app-expand-content>
  </div>
</section>

<section id="googleReview" class="bg-prime py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 text-center about">
        <h6>WE ARE TOP RATED</h6>
        <h1 class="fs-mouse">Chekcout our <span>Google Review</span></h1>
      </div>
    </div>
    <div class="row" [class.expandContent]="!googleReviewExpand">
      <div class="col-md-12 col-12 pt-2">
        <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25399135' frameborder='0' width='100%' height='500'></iframe>
      </div>
    </div>
    <app-expand-content (expandCollapse)="expandGoogleReview($event)"></app-expand-content>
  </div>
</section>

<section id="blogFeed" class="bg-prime py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 text-center about">
        <h1 class="fs-mouse">See Our <span>Weblog</span></h1>
      </div>
      <div [class.expandContent]="!blogExpand">
        <app-blogs-widget></app-blogs-widget>
      </div>
      <app-expand-content (expandCollapse)="expandBlog($event)"></app-expand-content>
    </div>
  </div>
</section>