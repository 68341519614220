<section
  id="contact"
  class="contact-area jarallax"
  data-jarallax='{"speed": 0.3}'
>
  <div class="container-fluid">
    <div class="row above"></div>
    <div class="row text-white text-center above footer pt-5">
      <div class="col pt-5 head">
        <p class="text-bold">Contact Us</p>
        <h6>To Be <span class="text-danger"> Our Partner</span></h6>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-dark footer pt-4">
    <div class="container">
      <div class="row justify-content-between text-white">
        <div class="col-sm-5 col-12">
          <p class="divider py-2">SUBSCRIBE NEWSLETTER</p>
          <div class="d-flex flex-row">
            <input type="text" placeholder="YOUR EMAIL" />
            <button class="ml-2">
              <i class="fal fa-long-arrow-right mx-3"></i>
            </button>
          </div>
          <p class="pt-serif divider py-2 mt-3">
            WATER N SPICES FOODSZ PRIVATE LIMITED
          </p>
          <p>
            <i class="fas fa-envelope mr-3 text-warning"></i> +91 7676135136
          </p>
          <p>
            <i class="fas fa-phone-alt mr-3 text-warning"></i>
            info@epanipuricart.in
          </p>
        </div>
        <div class="col-sm-5 col-12">
          <p class="divider py-2 text text-uppercase">Headquarters</p>
          <div></div>
          <p class="pt-serif">
            203, Liya ComplexHindpiri Main Road,Ranchi, JharkhandIndia, 834001
          </p>
          <p class="divider py-2 text text-uppercase">WORKSHOP</p>
          <p class="pt-serif">
            Plot No. 64, Surya NagarUnit-7,Bhubaneswar, Odisha, India, 751003
          </p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col pt-serif botton py-4">
          <span class="cursor-pointer"
            >Copyright@2023 Water N Spices Foodsz Pvt. Ltd. All rights reserved
          </span>
          <span class="ml-auto"> Terms & Conditions</span>
          <span class="ml-3">Privacy Policy</span>
        </div>
      </div>
    </div>
  </div>
</section>
