<app-auth-header *ngIf="backTo === '/landing'"></app-auth-header>
<div class="container-fluid h-100">
  <div class="row h-100 row-1 container-row">
    <div class="col-md-6 offset-md-3 card-non-hover">
      <div class="icon-div cursor-pointer" (click)="showLanding()">
        <fa-icon class="float-right" [icon]="faArrowLeft"></fa-icon>
      </div>
      <div
        class="sub-heading-text-1-semi-bold"
        style="margin: 1rem 0 2rem 0; padding: 0 1rem"
      >
        Application Under Review
      </div>
      <div>
        <div class="review-icon">
          <fa-icon [icon]="faUserClock" class="fa-md"></fa-icon>
        </div>
        <p>
          Your Application is submitted successfully, and currently under
          review.
        </p>
      </div>
    </div>
  </div>
</div>
<div  *ngIf="orderDetails" class="container-fluid h-100">
  <div class="row h-100 row-1 container-row" style="margin-top: 10px;">
    <div class="col-md-6 offset-md-3 card-non-hover">
      <div class="sub-heading-text-1-semi-bold" style="margin: 1rem 0 2rem 0; padding: 0 1rem">
        Order Summary
      </div>        
      <p></p>
      <div class="row d-sm-none d-none d-md-block d-lg-block">
        <table class="table table-bordered ">
          <thead>
            <tr>
              <th scope="col">Customer Name</th>
              <th scope="col">Email</th>
              <th *ngIf="(orderDetails.franchise_type === 'franchise')" scope="col">Model</th>
              <th *ngIf="!(orderDetails.franchise_type === 'franchise')" scope="col">Category</th>
              <th scope="col">Status</th>
              <th scope="col">Address</th>
              <th scope="col">Town</th>
              <th scope="col">State</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ orderDetails.title + ' ' + orderDetails.firstName + ' ' + orderDetails.lastName}}</td>
              <td>{{ orderDetails?.email}}</td>
              <td *ngIf="(orderDetails.franchise_type === 'franchise')">{{ orderDetails?.model_name + ' ' + orderDetails?.model_extension | titlecase}}</td>
              <th *ngIf="!(orderDetails.franchise_type === 'franchise')" scope="col">{{orderDetails?.franchise_type | titlecase}}</th>
              <td *ngIf="(orderDetails.status === 'in_review')">In Review</td>
              <td *ngIf="!(orderDetails.status === 'in_review')">{{ orderDetails?.status | titlecase }}</td>
              <td>{{ orderDetails?.shipping_address | titlecase}}</td>
              <td>{{ orderDetails?.town || 'NA'}}</td>
              <td>{{ orderDetails?.state}}</td>
            </tr>
          </tbody>
        </table>
        
      </div> 
      <!-- <div class="row d-md-none d-lg-none d-sm-block d-block mb-4">
        <div class="card  mb-4" *ngFor="let item of tableData">
         
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Customer Id :</b> {{ item?.device_id}}</li>
            <li class="list-group-item"><b>Order Id :</b> {{ item?.order_id}}</li>
            <li class="list-group-item"><b>Order Date :</b> {{ getDate(item?.launchWizobj?.date)}}</li>
            <li class="list-group-item"><b>Order Status :</b>{{ item?.launchWizobj?.status}}</li>
          </ul>
          <div class="card-body">
            <button class="primary-button rounded" (click)="launchWizard()">Launch Wizard</button>
          </div>
        </div>
        
      </div>  -->
      
  </div>   
  </div>
</div>