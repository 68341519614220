<div
  class="modal fade"
  bsModal
  #modal="bs-modal"
  [config]="{ backdrop: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-events-name"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" *ngIf="title">
        <h4 id="dialog-events-name" class="modal-title pull-left">
          {{ title }}
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="modal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [ngClass]="modalBodyClass ? 'p-0' : ''">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
