<header class="top-area headerC">
  <nav class="navbar navbar-expand-lg navbar-light bg-light transparent-navbar">
    <div class="container">
      <a class="navbar-brand oval-crop" routerLink="/">
        <img src="assets/img/epanipuricart.png" alt="EPanipuri Cart" />
      </a>
      <a class="navbar-brand black-logo oval-crop" routerLink="/">
        <img src="assets/img/epanipuricart.png" alt="EPanipuri Cart" />
      </a>
    </div>
  </nav>
</header>
