<!-- <app-auth-header></app-auth-header>
<app-custom-header></app-custom-header> -->
<app-custom-header>
    <div
      class="container-fluid container-col"
      style="margin-top: 135px !important; margin-bottom: 135px !important"
    >
      <div style="min-height: 500px;" class="row row-1 container-row">
        <!-- <h1 class="text-center construction-msg">
        <i class="icofont-lock"></i>Site Under Construction
      </h1> -->
  
        <div class="col-md-6 offset-md-3 card-non-hover">
          <div
            class="sub-heading-text-1-semi-bold"
            style="margin: 1rem 0 2rem 0; padding: 0 1rem"
          >
            Coming Soon
          </div>
        </div>
      </div>
    </div>
  </app-custom-header>
  