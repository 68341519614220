<app-blank-header></app-blank-header>
<div class="container-fluid h-100">
  <div class="row h-100 row-1 container-row">
    <div class="col-md-6 offset-md-1 container-col card-non-hover">
      <div style="margin-top: 2rem" class="otp-heading-div">
        <div
          class="sub-heading-text-1-semi-bold"
          style="margin: 1rem 0 2rem 0; padding: 0 1rem"
        >
          Reset Password
        </div>
      </div>
      <form
        class="w-100"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPassword()"
      >
        <div class="row justify-content-center">
          <div class="col-md-7 col-sm-12">
            <div class="form-group">
              <label class="form-check-label hint-text" for="emailId">
                Email Id
              </label>
              <input
                type="text"
                id="emailId"
                placeholder="Enter your Email ID"
                [formControlName]="'email'"
              />
            </div>
          </div>

          <div class="col-md-7 col-sm-12">
            <div class="form-group w-100 text-center">
              <button
                type="submit"
                class="primary-button"
                [disabled]="resetPasswordForm.invalid"
              >
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                <span *ngIf="!loading">Reset</span>
              </button>
            </div>
          </div>
          <hr class="w-100" />
          <div class="col-md-12 col-sm-12 row">
            <div>
              <p>Already a User? <a [routerLink]="'/login'">Login Now</a></p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
