<app-blank-header></app-blank-header>
<div class="container">
  <div>
    <span>
      <h2>Privacy Policy<br /></h2>
    </span>
  </div>
  <div>
    <h6>
      This Privacy Policy (“Policy”) describes the policies and procedures on
      the collection, use, disclosure and protection of your information when
      you use our website located at epanipuricart.com, or the Epanipuricart
      mobile application (collectively, “Epanipuricart Platform”) (“E-panipurii
      kartz”, “ Water N Spices Foodsz Private Limited ”), a private company
      established under the laws of India having its registered office at 203 ,
      Liya Complex , PP Compound , Main road , Ranchi-834001, Jharkhand. <br />
    </h6>
  </div>
  <div>
    <span>Your Privacy Matters<br /> </span>
  </div>
  <div>
    <p>
      E-panipurii kartz mission is to connect the tradition with technology to
      allow all street food to be more productive and successful. Central to
      this mission is our commitment to be transparent about the data we collect
      about you, how it is used and with whom it is shared. <br />
      This Privacy Policy applies when you use our Services (described below).<br />
      Data You Provide To Us<br />
      You provide data to create an account with us that is while
      Registration.<br />
      To create an account you need to provide data including your name, email
      address and/or mobile number, and a password. We use your data to provide,
      support, personalize and develop our Services.<br />
    </p>
  </div>
  <div>
    <span>
      <h6>Collection of Information<br /></h6>
    </span>
  </div>
  <div>
    <p>
      If you browse through this site without providing us with any personal
      information, we will gather and store some information about your visit,
      such as IP address, type of browser and operating system used, date and
      time you access our site, pages you visit, and if you linked to our
      website from another website, the address of that website. This
      information will not identify you personally and will not be linked back
      to you. </p>
      <p>There will be times, such as when you submit an auto lead request
      to a dealer or fill out our contact form, when we will need to obtain
      personally identifiable information from you or about you. Such personally
      identifiable information may include your name, address, e-mail address,
      telephone number and identification number. </p>
      <p>Use and Sharing of Information <br />
      At no time will we sell your personally-identifiable data without your
      permission unless set forth in this Privacy Policy. The information we
      receive about you or from you may be used by us or shared by us with our
      corporate affiliates, dealers, agents, vendors and other third parties to
      help process your request; to comply with any law, regulation, audit or
      court order; to help improve our website or the products or services we
      offer; for research; to better understand our customers’ needs; to develop
      new offerings; and to alert you to new products and services (of us or our
      business associates) in which you may be interested. We may also combine
      information you provide us with information about you that is available to
      us internally or from other sources in order to better serve you. </p>
      <p>We do not share, sell, trade or rent your personal information to third parties
      for unknown reasons.
      <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Security<br /></h6
    ></span>
  </div>
  <div>
    <p>
      We safeguard your privacy using known security standards and procedures
      and comply with applicable privacy laws. Our websites combine
      industry-approved physical, electronic and procedural safeguards to ensure
      that your information is well protected though it’s life cycle in our
      infrastructure. </p>
      <p>Sensitive data is hashed or encrypted when it is stored in
      our infrastructure. Sensitive data is decrypted, processed and immediately
      re-encrypted or discarded when no longer necessary. We host web services
      in audited data centers, with restricted access to the data processing
      servers. Controlled access, recorded and live-monitored video feeds, 24/7
      staffed security and biometrics provided in such data centers ensure that
      we provide secure hosting.
      <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Legal Disclosures<br /></h6>
    </span>
  </div>
  <div>
    <p>
      We may need to share your data when we believe it’s required by law or to
      help protect the rights and safety of you, us or others. <br /> It is possible
      that we will need to disclose information about you when required by law,
      or other legal process or if we have a good faith belief that disclosure
      is reasonably necessary to (1) investigate, prevent, or take action
      regarding suspected or actual illegal activities or to assist government
      enforcement agencies; (2) enforce our agreements with you, (3) investigate
      and defend ourselves against any third-party claims or allegations, (4)
      protect the security or integrity of our Service (such as by sharing with
      companies facing similar threats); or (5) exercise or protect the rights
      and safety of E-panipurii kartz, our Members, personnel, or others. We
      attempt to notify Members about legal demands for their personal data when
      appropriate in our judgment, unless prohibited by law or court order or
      when the request is an emergency. We may dispute such demands when we
      believe, in our discretion, that the requests are overbroad, vague or lack
      proper authority, but we do not promise to challenge every demand. .<br />
    </p>
  </div>
  <div>
    <span>
      <h6>Opt-Out Policy<br /></h6>
    </span>
  </div>
  <div>
    <p>
      Please email
      <a href="mailto:info@epanipuricart.in">info@epanipuricart.in</a> if you no
      longer wish to receive any information from us.<br />
    </p>
  </div>
  <div>
    <span>
      <h6>Changes to this Privacy Policy <br /></h6>
    </span>
  </div>
  <div>
    <p>
      Our privacy policy was last updated on May ,2021. We may change our
      Privacy Policy from time to time. If we do, we will update this Privacy
      Policy on our website.<br />
    </p>
  </div>
  <div>
    <span>
      <h6>Questions<br /></h6>
    </span>
  </div>
  <div>
    <p>
      If you have any questions about our Privacy Policy, please e-mail your
      questions to us at
      <a href="mailto:info@epanipuricart.in">info@epanipuricart.in</a><br />
    </p>
  </div>
  <div>
    <span>
      <h6>GRIEVANCE OFFICER AND E-panipurii kartz PLATFORM SECURITY<br /></h6>
    </span>
  </div>
  <div>
    <p>
      If you have any queries relating to the processing or usage of information
      provided by you in connection with this Policy, please email us at
      info@epanipuricart.in or write to our Grievance Officer at the following
      address:<br />
      <strong>Tanya Raj</strong><br />
      <strong>E-panipurii kartz Grievance Officer</strong><br />
      #22, Bannerghatta Main Rd, Vijayashri Layout, Syndicate Bank Colony, gate,
      Hulimavu, Bengaluru, Karnataka 560076
    </p>
  </div>
</div>
