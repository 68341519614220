<div class="partner-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Compliance</span>
      <h2>We initiate Compliance with</h2>
    </div>

    <div class="row compliant-row">
      <div class="col-md-4 col-sm-12 text-center">
        <img
          src="assets/img/logo/fssai.png"
          alt="FSSAI"
          class="compliant-logo"
        />
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <img
          src="assets/img/logo/gst-logo1.png"
          alt="GST"
          class="compliant-logo"
          id="gst"
        />
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <img
          src="assets/img/logo/isi_img.png"
          alt="ISI"
          class="compliant-logo"
        />
      </div>
    </div>
  </div>
</div>
