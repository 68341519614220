<!-- <app-preloader></app-preloader> -->
<div class="main-body">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<!-- 
<div
  id="forms"
  class="Floatbtn op5"
  container="body"
  placement="left"
  containerClass="cursor-pointer"
  *ngIf="!hideFloatMenu"
>
  <a href="tel:+917676135136" target="_blank"
    ><i id="addIcon" class="material-icons">phone</i></a
  >
</div>
<div
  id="drawings"
  class="Floatbtn op4"
  container="body"
  placement="left"
  containerClass="cursor-pointer"
  *ngIf="!hideFloatMenu"
>
  <a href="mailto:info@epanipuricart.in" target="_blank">
    <i id="addIcon" class="material-icons">email</i></a
  >
</div> -->
<!-- <div
  id="forms"
  class="Floatbtn op6"
  container="body"
  placement="left"
  containerClass="cursor-pointer"
  *ngIf="hideFloatMenu"
>
  <span  target="_blank"
    ><i id="addIcon" class="material-icons">restaurant</i></span
  >
</div> -->

