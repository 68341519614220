<app-auth-header></app-auth-header>

<div class="container">
  <mat-card class="mat-elevation-z6">
    <mat-card-header> <h4>Advantages</h4> </mat-card-header>
    <mat-card-content>
      <p>
        Becoming E-Panipurii Kartz Franchise owner is not that complicated. If
        you are anyone with an entrepreneurial mindset, with independence as
        your aim, this is the perfect business module for you.
      </p>
      <p>
        Usually, every street has a panipuri corner. But this is for the very
        first time where you will get an opportunity to sell panipuri from
        absolutely anywhere! As we are extending the option of selling panipuri
        with super hygienity, which you can sell with no human interference.
      </p>
      <p>
        With E-Panipurii Kartz franchise, you are able to sell Panipuri at any
        hour. We have made ePanipuri cart available in your town for selling
        100% contactless panipuri.
      </p>
    </mat-card-content>
  </mat-card>
</div>
