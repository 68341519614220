// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDSsD1Iys2QQLQdXpKD13hq1MpA_Wtj6ew',
    authDomain: 'e-panipuricart.firebaseapp.com',
    projectId: 'e-panipuricart',
    storageBucket: 'e-panipuricart.appspot.com',
    messagingSenderId: '875772694959',
    appId: '1:875772694959:web:244c9c01a4a5812d909f5a',
    measurementId: 'G-L9HGEQ252J',
  },
  serverURL: 'https://epanipuricart.com/franchisee',
  orderNowServerURL: 'https://epanipuricart.com/orderOnline',
  socketURL: 'https://epanipuricart.com/orderOnline',
  iotURL: 'https://epanipuricart.com/wizard',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
