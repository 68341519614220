<div class="partner-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Awards</span>
      <h2>Our Recognition</h2>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="partner-item">
          <a href="https://www.startupindia.gov.in/" target="_blank"
            ><img
              src="assets/img/startup-india -final.png"
              width="150"
              height="80"
              alt="image"
          /></a>
          <!-- <p>Startup India</p> -->
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="partner-item">
          <a href="https://www.iimsambalpur.ac.in/" target="_blank"
            ><img
              src="assets/img/iim-sambalpur-2.jpg"
              width="150"
              height="80"
              alt="image"
          /></a>
          <!-- <p>IIM Sambalpur</p> -->
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="partner-item">
          <a href="http://www.mace.ac.in/" target="_blank"
            ><img
              src="assets/img/iot-mace.jfif"
              width="150"
              height="80"
              alt="image"
          /></a>
          <!-- <p>Iot Mace Club</p> -->
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="partner-item">
          <a href="http://desicolab.com/" target="_blank"
            ><img
              src="assets/img/desi-colab.png"
              width="150"
              height="80"
              alt="image"
          /></a>
          <!-- <p>Desi Colab</p> -->
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="partner-item">
          <a href="http://ecell.iith.ac.in/" target="_blank"
            ><img
              src="assets/img/e-cell.png"
              width="150"
              height="80"
              alt="image"
          /></a>
          <!-- <p>E-Cell IIT Hyderabad</p> -->
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
