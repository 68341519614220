<mat-drawer-container class="example-container" autosize>
  <mat-drawer
    opened="{{ sharedService.getSideBarStatus() }}"
    (closed)="sharedService.toggleSidebar()"
    class="example-sidenav"
    mode="over"
    position="end"
  >
    <ul class="navbar-nav">
      <a class="navbar-brand" routerLink="/">
        <img src="assets/img/epanipuricart.png" alt="EPanipuri Cart" />
      </a>

      <li class="nav-item">
        <div
          class="nav-link cursor-pointer"
          (click)="routeToLink('dashboard/controlPanel')"
          [ngClass]="{
            active: isRouteActive('login'),
            titleCase: isLoggedIn
          }"
        >
          {{ userName | titlecase }}
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link cursor-pointer"
          (click)="routeToLink('ordernow/store-locator')"
        >
          Order Now
        </div>
      </li>
      <li class="nav-item">
        <div class="nav-link cursor-pointer" (click)="routeToLink('blogs')">
          Blogs
        </div>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn">
        <div class="nav-link cursor-pointer" (click)="logout()">Logout</div>
      </li>
      <li class="nav-item" *ngIf="!isLoggedIn">
        <div class="nav-link cursor-pointer" (click)="routeToLink('register')">
          Partner With Us
        </div>
      </li>
    </ul>
  </mat-drawer>
  <mat-drawer-content class="example-sidenav-content">
    <app-header-one></app-header-one>
    <div
      class="notification-popup bg-prime"
      [ngClass]="{ closed: notificationPopupClosed }"
      *ngIf="!this.sharedService.hideOfferNotificationPopup"
    >
      <div class="header">
        <button
          mat-icon-button
          color="accent"
          (click)="closeNotificationPopup()"
        >
          <mat-icon> close </mat-icon>
        </button>
      </div>
      <div class="content">
        <h4>Click to get exciting offers!!</h4>
      </div>
      <div class="buttons">
        <button class="btn btn-primary" (click)="openOfferForm()">OK</button>
      </div>
    </div>
    <div
      id="home"
      class="main-banner item-bg-one bg-prime jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <div class="main-banner-content">
              <h1>Sooch Nayi... <span> Swaad Wahi </span></h1>
              <h3>
                Transform Your Panipuri Stall with our Automatic Panipuri Vending Machine and Unique Recipe of Quality Masala
              </h3>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-3 col-12 d-flex justify-content-center">
                <button
                  class="
                    btn btn-primary
                    d-flex
                    align-items-center
                    justify-content-center
                    text-nowrap
                    pl-3 pr-3
                  "
                  (click)="routeToLink('register')"
                >
                  BE OUR FRANCHISEE PARTNER
                </button>
              </div>
            </div>

            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <i
                class="
                  fal
                  fa-angle-double-down
                  text-white
                  h2
                  mt-4
                  font-weight-light
                  mb-0
                "
              ></i>

              <a
                href="#about"
                class="scroll_down"
                style="color: #fff; font-size: 20px"
                >SCROLL DOWN</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-about></app-about>
    <!-- <app-contact></app-contact> -->
  </mat-drawer-content>
</mat-drawer-container>
