<section class="instagram-area">
    <div class="instagram-title">
        <h3><a href="#" target="_blank"><i class="icofont-instagram"></i> Follow Us On @fryio</a></h3>
    </div>
    <div class="instagram-slides owl-carousel owl-theme">
        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img1.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img2.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img3.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img4.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img5.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img6.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img1.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img2.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img3.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img4.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img5.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="#"><img src="assets/img/gallery-img6.jpg" alt="instagram-image"></a>
        </div>
    </div>
</section>